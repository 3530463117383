import { Link } from "@/components/Link/Link.component";
import { Translation } from "@/components/Translation/Translation.component";
type PaywallTermsLink = {
  readonly termsAndConditionsUrl: string;
  readonly pricePerQuarter: string;
};
export function PaywallTermsLink({
  termsAndConditionsUrl,
  pricePerQuarter
}: PaywallTermsLink) {
  return <div className="mx-auto max-w-lg text-center text-xs" data-sentry-component="PaywallTermsLink" data-sentry-source-file="PaywallTermsLink.component.tsx">
      <Translation da={`* Mindstepris: ${pricePerQuarter} for 3 måneder v/betaling med kreditkort. Fortsætter som løbende abonnement for 3 måneder ad gangen. Kan opsiges med løbende måned plus en måned til udgangen af en 3 måneders periode. Fortrydelsesret i medfør af Forbrugeraftaleloven.`} de={`* Mindestpreis: ${pricePerQuarter} für 3 Monate bei Zahlung mit Kreditkarte. Wird als fortlaufendes Abonnement für 3 Monate fortgesetzt. Kann mit einer Kündigungsfrist von einem Monat zum Ende eines 3-Monats-Zeitraums gekündigt werden. Widerrufsrecht gemäß dem Verbrauchervertragsgesetz.`} en={`* Minimum price: ${pricePerQuarter} for 3 months when paying by credit card. Continues as a recurring subscription for 3 months at a time. Can be terminated with one month's notice at the end of a 3-month period. Right of withdrawal in accordance with the Consumer Contracts Act.`} no={`* Minimumspris: ${pricePerQuarter} for 3 måneder ved betaling med kredittkort. Fortsetter som løpende abonnement for 3 måneder av gangen. Kan sies opp med en måneds varsel ved utgangen av en 3-måneders periode. Angrerett i henhold til forbrukerloven.`} sv={`* Minsta pris: ${pricePerQuarter} för 3 månader vid betalning med kreditkort. Fortsätter som löpande prenumeration i 3 månader i taget. Kan sägas upp med en månads varsel i slutet av en 3-månaders period. Ångerrätt enligt konsumentavtalslagen.`} data-sentry-element="Translation" data-sentry-source-file="PaywallTermsLink.component.tsx" />{" "}
      <Link className="inline underline hover:text-primary" href={termsAndConditionsUrl} data-sentry-element="Link" data-sentry-source-file="PaywallTermsLink.component.tsx">
        <Translation da="Læs mere her" de="Lesen Sie mehr hier" en="Read more" no="Les mer her" sv="Läs mer här" data-sentry-element="Translation" data-sentry-source-file="PaywallTermsLink.component.tsx" />
      </Link>
      .
    </div>;
}