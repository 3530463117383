import { Translation } from "@/components/Translation/Translation.component";
import type { Translations } from "@/components/Translation/translation.types";
type PaywallTitleProps = {
  readonly model: Translations;
};
export function PaywallTitle({
  model
}: PaywallTitleProps) {
  return <h1 className="pb-2 text-center font-heading text-3xl font-theme-bold md:text-4xl lg:text-5xl" data-sentry-component="PaywallTitle" data-sentry-source-file="PaywallTitle.component.tsx">
      <Translation model={model} data-sentry-element="Translation" data-sentry-source-file="PaywallTitle.component.tsx" />
    </h1>;
}