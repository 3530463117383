"use client";

import { usePathname } from "next/navigation";
import { useState } from "react";
import { ConsumerCard } from "@/components/Paywall/_components/ConsumerCard/ConsumerCard.components";
import { CorporateCard } from "@/components/Paywall/_components/CorporateCard/CorporateCard.components";
import { useSite } from "@/contexts/site/site.context";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { conversionEvents } from "@/hooks/tracking/kilkaya/klikaya.types";
import { useKilkayaClickEvent } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, pending } from "@/services/utilities/fetchable";
import { SwitchFetchable } from "../SwitchFetchable/SwitchFetchable.component";
import { PaywallAddTrialLink } from "./_components/PaywallAddTrialLink/PaywallAddTrialLink.component";
import { PaywallLayout } from "./_components/PaywallLayout/PaywallLayout.component";
import { PaywallTermsLink } from "./_components/PaywallTermsLink/PaywallTermsLink.component";
import { PaywallTitle } from "./_components/PaywallTitle/PaywallTitle.component";
import { SuccessBox } from "./_components/SuccessBox/SuccessBox.component";
import { useAddTrial } from "./_hooks/use-add-trial.hook";
import type { PaywallModel } from "./models/paywall.model";
type PaywallProps = {
  readonly className?: string;
  readonly model: PaywallModel;
  readonly redirectUrl: string;
  readonly initialSubmissionState?: Fetchable;
};
export const trialLinkTranslations = {
  da: "Få et uforpligtende prøveabonnement her.",
  de: "Erhalten Sie hier ein unverbindliches Probeabonnement.",
  en: "Get a non-binding trial subscription here.",
  no: "Få et uforpliktende prøveabonnement her.",
  sv: "Få ett icke-bindande provabonnemang här."
};
export function AuthenticatedPaywall({
  className,
  model,
  redirectUrl,
  initialSubmissionState = initial()
}: PaywallProps) {
  const pathname = usePathname();
  const {
    hasMedielogin = false
  } = useSiteConfiguration();
  const {
    name: siteName,
    domain
  } = useSite();
  const [containerState, setContainerState] = useState(initialSubmissionState);
  const {
    addTrialToCurrentProfile
  } = useAddTrial();
  const {
    trackClick
  } = useKilkayaClickEvent();
  const {
    product,
    trial,
    apps,
    articleId,
    termsAndConditionsUrl
  } = model;
  async function trialClick() {
    setContainerState(pending());
    trackClick({
      position: "pw-adtr-btn",
      toUrl: `https://${domain}${pathname}`,
      url: `https://${domain}${pathname}`
    });
    const result = await addTrialToCurrentProfile(articleId);
    setContainerState(result);
  }
  return <SwitchFetchable fetchable={containerState} renderDefault={() => <PaywallLayout className={className}>
          <PaywallTitle model={{
      da: `Du er logget ind, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`,
      de: `Sie sind angemeldet, aber mit keinem aktiven Abonnement für ${siteName} verbunden`,
      en: `You are logged in, but you do not have an active subscription to ${siteName}`,
      no: `Du er logget inn, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`,
      sv: `Du är inlogg, men har inte ett aktivt abonnemang på ${siteName}`
    }} />

          <ConsumerCard model={{
      apps,
      product,
      siteName,
      url: `/shop/consumer?articleId=${articleId}&redirectUrl=${redirectUrl}`
    }} state={containerState} variant="primary" />

          <CorporateCard apps={apps} state={containerState} url={`/shop/corporate?articleId=${articleId}&redirectUrl=${redirectUrl}`} variant="primary" />

          {trial !== null && !trial.isUserTrialExpired ? <PaywallAddTrialLink articleId={articleId} state={containerState} trial={trial} onClick={trialClick} /> : null}

          {product.pricePerQuarter !== null ? <div className="container flex max-w-lg flex-col gap-2 text-center text-xs">
              <PaywallTermsLink pricePerQuarter={product.pricePerQuarter} termsAndConditionsUrl={termsAndConditionsUrl} />
            </div> : null}
        </PaywallLayout>} renderSucceeded={() => <SuccessBox className={className} model={{
    appStoreUrl: apps?.appStoreUrl ?? null,
    conversionEventTags: conversionEvents.addTrialArticleEvent,
    googlePlayUrl: apps?.googlePlayUrl ?? null,
    hasMedielogin,
    state: containerState
  }} />} data-sentry-element="SwitchFetchable" data-sentry-component="AuthenticatedPaywall" data-sentry-source-file="AuthenticatedPaywall.component.tsx" />;
}