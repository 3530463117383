import clsx from "clsx";
import type { ReactNode } from "react";
import { SolidExclamationCircleIcon } from "../Icons/components/HeroIcons/SolidExclamationCircleIcon.component";
type Severities = "info" | "error" | "success" | "warning";
const alertColor: Record<Severities, string> = {
  error: "text-error-600",
  info: "text-info-600",
  success: "text-success-600",
  warning: "text-warning-600"
};
const backgroundColor: Record<Severities, string> = {
  error: "bg-error-50",
  info: "bg-info-50",
  success: "bg-success-50",
  warning: "bg-warning-50"
};
type AlertProps = {
  readonly size?: "sm" | "md";
  readonly severity?: Severities;
  readonly children: ReactNode;
  readonly className?: string;
  readonly hasBackground?: boolean;
};
export function Alert({
  children,
  size = "sm",
  severity = "error",
  className,
  hasBackground = true
}: AlertProps) {
  return <div className={clsx(className, alertColor[severity], "flex select-none hyphens-manual break-words rounded-sm border border-inherit", size === "sm" && "gap-x-1 p-1 text-sm", size === "md" && "gap-x-2 p-2", hasBackground && backgroundColor[severity])} data-testid="alert" data-sentry-component="Alert" data-sentry-source-file="Alert.component.tsx">
      <SolidExclamationCircleIcon className={clsx("aspect-square shrink-0 fill-current", size === "sm" && "h-2.5", size === "md" && "h-3")} data-sentry-element="SolidExclamationCircleIcon" data-sentry-source-file="Alert.component.tsx" />

      <div>{children}</div>
    </div>;
}