import { LoginLink } from "@/components/Login/LoginLink/LoginLink.component";
import { Translation } from "@/components/Translation/Translation.component";
export function PaywallLoginNotification() {
  return <div className="absolute -right-1 top-1 flex select-none whitespace-nowrap border border-solid border-silver bg-white px-2 py-1 text-xs uppercase sm:top-1.5 lg:-right-2.5 lg:top-1.5" data-sentry-component="PaywallLoginNotification" data-sentry-source-file="PaywallLoginNotification.component.tsx">
      <span className="border-r border-solid border-silver pr-1">
        <Translation da="Allerede abonnent?" de="Bereits registriert?" en="Already a subscriber?" no="Allerede abonnent?" sv="Redan prenumerant?" data-sentry-element="Translation" data-sentry-source-file="PaywallLoginNotification.component.tsx" />
      </span>
      <LoginLink className="ml-1 font-theme-bold text-primary hover:text-primary-hover" position="pw_login" variant="inherit" data-sentry-element="LoginLink" data-sentry-source-file="PaywallLoginNotification.component.tsx">
        <Translation da="Log ind her" de="Hier anmelden" en="Log in here" no="Logg inn her" sv="Logga in här" data-sentry-element="Translation" data-sentry-source-file="PaywallLoginNotification.component.tsx" />
      </LoginLink>
    </div>;
}