import clsx from "clsx";
import { RegularButton } from "@/components/Button/RegularButton/RegularButton.component";
import { Card } from "@/components/Card/Card.component";
import { Divider } from "@/components/Divider/Divider.component";
import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage.component";
import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { Translation } from "@/components/Translation/Translation.component";
import { hasFailed, initial, isPending } from "@/services/utilities/fetchable";
import type { ErrorMessageStatus, Fetchable } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
type TrialCardProps = {
  readonly model: {
    readonly siteName: string;
    readonly trialDays: number;
    readonly variant?: "primary" | "secondary";
  };
  readonly state?: Fetchable;
  readonly onClick: VoidFunction;
};
export const trialCardConfirmationTranslations = {
  da: "Prøv nu",
  de: "Jetzt testen",
  en: "Try now",
  no: "Prøv nå",
  sv: "Prova nu"
};
export function TrialCard({
  model: {
    trialDays,
    siteName,
    variant = "primary"
  },
  state = initial(),
  onClick
}: TrialCardProps) {
  return <Card className="flex h-full flex-col justify-between gap-y-3 rounded border-silver text-center lg:px-4" data-sentry-element="Card" data-sentry-component="TrialCard" data-sentry-source-file="TrialCard.component.tsx">
      <h1 className={clsx("hyphens-manual break-words text-xl font-bold leading-tight")}>
        <Translation da="Prøveabonnement" de="Testabonnement" en="Trial subscription" no="Prøveabonnement" sv="Prova på-prenumeration" data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
      </h1>

      <Divider className="relative" orientation="horizontal" data-sentry-element="Divider" data-sentry-source-file="TrialCard.component.tsx" />

      <p className="text-left text-sm md:text-base">
        <Translation da={`Med ${trialDays} dages gratis prøve får du:`} de={`Mit ${trialDays} Tagen kostenlosem Test erhalten Sie:`} en={`With ${trialDays} days free trial you get:`} no={`Med ${trialDays} dagers gratis prøve får du:`} sv={`Med ${trialDays} dagars gratis prov får du:`} data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
      </p>

      <List className="gap-1 text-left text-sm md:text-base" type="checkmark" data-sentry-element="List" data-sentry-source-file="TrialCard.component.tsx">
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="TrialCard.component.tsx">
          <Translation da={inlineJsx`Uforpligtende Premium adgang til alt indhold på ${siteName}`} de={inlineJsx`Unverbindlicher Premium-Zugang zu allen Inhalten auf ${siteName}`} en={inlineJsx`Non-binding premium access to all content on ${siteName}`} no={inlineJsx`Uforpliktende Premium-tilgang til alt innhold på ${siteName}`} sv={inlineJsx`Icke-bindande premiumåtkomst till allt innehåll på ${siteName}`} data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="TrialCard.component.tsx">
          <Translation da="Ubegrænset adgang til vores arkiv" de="Unbegrenzten Zugriff auf unser Archiv" en="Unlimited access to our archive" no="Ubegrenset tilgang til vårt arkiv" sv="Obegränsad tillgång till vårt arkiv" data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="TrialCard.component.tsx">
          <Translation da="Dagligt nyhedsbrev med de vigtigste nyheder inden for området" de="Täglicher Newsletter mit den wichtigsten Nachrichten in Ihrem Bereich" en="Daily newsletter with the most important news in the field" no="Daglig nyhetsbrev med de viktigste nyhetene innen området" sv="Dagligt nyhetsbrev med de viktigaste nyheterna inom området" data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="TrialCard.component.tsx">
          <Translation da="Der kræves ingen kreditkortoplysninger" de="Keine Kreditkarteninformationen erforderlich" en="No credit card information required" no="Ingen kredittkortopplysninger kreves" sv="Inga kreditkortsuppgifter krävs" data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
        </ListItem>
      </List>

      <RegularButton isPending={isPending(state)} testId="add-trial-button" variant={variant} onClick={onClick} data-sentry-element="RegularButton" data-sentry-source-file="TrialCard.component.tsx">
        <Translation model={trialCardConfirmationTranslations} data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
      </RegularButton>

      {hasFailed(state) ? <ErrorMessage status={(state.errorMessage as ErrorMessageStatus)} /> : null}
    </Card>;
}