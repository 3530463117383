"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useSite } from "@/contexts/site/site.context";
import { ConsumerCard } from "./_components/ConsumerCard/ConsumerCard.components";
import { CorporateCard } from "./_components/CorporateCard/CorporateCard.components";
import { PaywallAddTrialLink } from "./_components/PaywallAddTrialLink/PaywallAddTrialLink.component";
import { PaywallLayout } from "./_components/PaywallLayout/PaywallLayout.component";
import { PaywallLoginNotification } from "./_components/PaywallLoginNotification/PaywallLoginNotification.component";
import { PaywallTermsLink } from "./_components/PaywallTermsLink/PaywallTermsLink.component";
import { PaywallTitle } from "./_components/PaywallTitle/PaywallTitle.component";
import type { PaywallModel } from "./models/paywall.model";
type PaywallProps = {
  readonly className?: string;
  readonly model: PaywallModel;
  readonly redirectUrl: string;
};
export const trialLinkTranslations = {
  da: "Få et uforpligtende prøveabonnement her.",
  de: "Erhalten Sie hier ein unverbindliches Probeabonnement.",
  en: "Get a non-binding trial subscription here.",
  no: "Få et uforpliktende prøveabonnement her.",
  sv: "Få ett icke-bindande provabonnemang här."
};
export function UnauthenticatedPaywall({
  className,
  model,
  redirectUrl
}: PaywallProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const {
    name: siteName
  } = useSite();
  const {
    product,
    apps,
    trial,
    articleId,
    termsAndConditionsUrl
  } = model;
  const onAddTrialLinkClick = () => {
    const searchParamsString = searchParams.toString();
    const url = `/profile/create/trial?redirectUrl=${encodeURIComponent(`${pathname}${searchParamsString ? `?${searchParamsString}` : ""}`)}`;
    router.push(url);
  };
  return <PaywallLayout className={className} data-sentry-element="PaywallLayout" data-sentry-component="UnauthenticatedPaywall" data-sentry-source-file="UnauthenticatedPaywall.component.tsx">
      <PaywallLoginNotification data-sentry-element="PaywallLoginNotification" data-sentry-source-file="UnauthenticatedPaywall.component.tsx" />

      <PaywallTitle model={{
      da: `Køb abonnement og få adgang til hele ${siteName}`,
      de: `Abonnement kaufen und Zugang zu ${siteName} erhalten`,
      en: `Buy a subscription and get access to all of ${siteName}`,
      no: `Kjøp abonnement og få tilgang til hele ${siteName}`,
      sv: `Köp en prenumeration och få tillgång till hela ${siteName}`
    }} data-sentry-element="PaywallTitle" data-sentry-source-file="UnauthenticatedPaywall.component.tsx" />

      <ConsumerCard model={{
      apps,
      product,
      siteName,
      url: `/shop/consumer?articleId=${articleId}&redirectUrl=${encodeURIComponent(redirectUrl)}`
    }} variant="primary" data-sentry-element="ConsumerCard" data-sentry-source-file="UnauthenticatedPaywall.component.tsx" />

      <CorporateCard apps={apps} url={`/shop/corporate?articleId=${articleId}&redirectUrl=${encodeURIComponent(redirectUrl)}`} variant="primary" data-sentry-element="CorporateCard" data-sentry-source-file="UnauthenticatedPaywall.component.tsx" />

      {trial !== null ? <PaywallAddTrialLink articleId={articleId} trial={trial} onClick={onAddTrialLinkClick} /> : null}

      {product.pricePerQuarter !== null ? <PaywallTermsLink pricePerQuarter={product.pricePerQuarter} termsAndConditionsUrl={termsAndConditionsUrl} /> : null}
    </PaywallLayout>;
}