import clsx from "clsx";
import type { ReactNode } from "react";
type PaywallLayoutProps = {
  readonly children: ReactNode;
  readonly className?: string;
};
export function PaywallLayout({
  children,
  className
}: PaywallLayoutProps) {
  return <article className={clsx(className, "relative text-sm md:text-base lg:mr-2.5")} data-sentry-component="PaywallLayout" data-sentry-source-file="PaywallLayout.component.tsx">
      <div className="flex flex-col gap-y-2 bg-whisper px-2 py-4 lg:px-4 lg:py-8">
        {children}
      </div>
    </article>;
}