import { NextResponse } from "next/server";
import type { NextRequest } from "next/server";

const tabloidDomains = new Set(["kforum.dk"]);
export const monitorDomains = new Set([
  "monitor.watchmedier.dk",
  "byrummonitor.dk",
  "uddannelsesmonitor.dk",
]);

export const config = {
  matcher: [
    // Match all paths except for:
    // 1. /api routes
    // 2. /_next (Next.js internals)
    // 3. /fonts (inside /public)
    // 4. /images (inside /public)
    // 5. /favicons (inside /public)
    // 6. all root files inside /public (e.g. /favicon.ico)
    // 7. /shop (for now)
    // 8. /profile (for now)
    // 9. /monitoring (used by Sentry)
    // 10. /metrics (used by Prometheus)
    "/((?!api|_next|fonts|images|favicons|shop|profile|monitoring|metrics|favicon.ico|robots.txt|ai.txt).*)",
  ],
};

export function middleware(request: NextRequest) {
  const overrideHost = process.env.CONTENT_API_DEFAULT_HOST ?? null;
  const url = request.nextUrl.clone();

  const domain =
    overrideHost ??
    request.headers
      .get("host")
      ?.replace(/^(local\.|localhost\.|dev\.|test\.)/u, "") ??
    null;

  if (shouldShortCircuitFrontpageHeadRequest(request)) {
    return new NextResponse();
  }

  if (domain !== null && tabloidDomains.has(domain)) {
    return NextResponse.rewrite(
      new URL(`/tabloid/${domain}${url.pathname}${url.search}`, url),
    );
  }

  if (domain !== null && monitorDomains.has(domain)) {
    return NextResponse.rewrite(
      new URL(`/monitor/${domain}${url.pathname}${url.search}`, url),
    );
  }

  return NextResponse.rewrite(
    new URL(`/watch/${domain}${url.pathname}${url.search}`, url),
  );
}

// To avoid HEAD requests resulting in calling underlying APIs, we short-circuit them.
function shouldShortCircuitFrontpageHeadRequest(request: NextRequest) {
  return request.nextUrl.pathname === "/" && request.method === "HEAD";
}
