import { clsx } from "clsx";
import type { ReactNode } from "react";
import { Link } from "@/components/Link/Link.component";
type BadgeProps = {
  readonly className?: string;
  readonly children: string | ReactNode;
  readonly targetUrl?: string | null;
};
export function Badge({
  className,
  children,
  targetUrl = null
}: BadgeProps) {
  return <div className={clsx(className, "w-fit max-w-full select-none overflow-hidden bg-primary text-[length:var(--theme-font-size-badge)] font-theme-bold uppercase tracking-[var(--theme-letter-spacing-badge)] text-white")} data-sentry-component="Badge" data-sentry-source-file="Badge.component.tsx">
      {targetUrl !== null ? <Link className="block truncate px-1.5 py-1 transition-colors hover:bg-primary-hover" href={targetUrl}>
          {children}
        </Link> : <span className="block truncate px-1.5 py-1">{children}</span>}
    </div>;
}