"use client";

import clsx from "clsx";
import { RegularLinkButton } from "@/components/Button/RegularLinkButton/RegularLinkButton.component";
import { Card } from "@/components/Card/Card.component";
import { Divider } from "@/components/Divider/Divider.component";
import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { monitorDomains } from "@/middleware";
import { initial, isPending, type Fetchable } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import type { PaywallModel } from "../../models/paywall.model";
type ConsumerCardProps = {
  readonly model: {
    readonly apps: PaywallModel.Apps | null;
    readonly product: PaywallModel.Product;
    readonly siteName: string;
    readonly url: string;
  };
  readonly state?: Fetchable;
  readonly variant?: "primary" | "outline";
};
export function ConsumerCard({
  model: {
    apps,
    product: {
      billingPeriod,
      pricePerQuarter
    },
    siteName,
    url
  },
  variant = "primary",
  state = initial()
}: ConsumerCardProps) {
  // TODO (JPWM-21149): Remove this when a generic text has been written
  const {
    domain
  } = useSite();
  const isMonitor = monitorDomains.has(domain);
  return <Card className="flex h-full flex-col justify-between gap-y-3 rounded border-silver text-center lg:px-4" data-sentry-element="Card" data-sentry-component="ConsumerCard" data-sentry-source-file="ConsumerCard.components.tsx">
      <h1 className={clsx("hyphens-manual break-words text-xl font-bold leading-tight")}>
        <Translation da="Personligt abonnement" de="Persönliches Abonnement" en="Personal subscription" no="Personlig abonnement" sv="Personlig prenumeration" data-sentry-element="Translation" data-sentry-source-file="ConsumerCard.components.tsx" />
      </h1>
      <Divider className="relative" orientation="horizontal" data-sentry-element="Divider" data-sentry-source-file="ConsumerCard.components.tsx" />
      <p className="text-left text-sm md:text-base">
        <Translation da="Med et personligt abonnement får du:" de="Mit einem persönlichen Abonnement erhalten Sie:" en="With a personal subscription you get:" no="Med et personlig abonnement får du:" sv="Med en personlig prenumeration får du:" data-sentry-element="Translation" data-sentry-source-file="ConsumerCard.components.tsx" />
      </p>
      <List className="gap-1 text-left text-sm md:text-base" type="checkmark" data-sentry-element="List" data-sentry-source-file="ConsumerCard.components.tsx">
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerCard.components.tsx">
          <Translation da={inlineJsx`Premium-adgang til alt indhold på ${siteName}`} de={inlineJsx`Premium-Zugang zu allen Inhalten auf ${siteName}`} en={inlineJsx`Premium access to all content on ${siteName}`} no={inlineJsx`Premium-tilgang til alt innhold på ${siteName}`} sv={inlineJsx`Premium-åtkomst till allt innehåll på ${siteName}`} data-sentry-element="Translation" data-sentry-source-file="ConsumerCard.components.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerCard.components.tsx">
          <Translation da="Ubegrænset adgang til vores arkiv" de="Unbegrenzten Zugriff auf unser Archiv" en="Unlimited access to our archive" no="Ubegrenset tilgang til vårt arkiv" sv="Obegränsad tillgång till vårt arkiv" data-sentry-element="Translation" data-sentry-source-file="ConsumerCard.components.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerCard.components.tsx">
          {isMonitor ? <Translation da="Dagligt nyhedsbrev med de vigtigste nyheder inden for området" de="Täglicher Newsletter mit den wichtigsten Nachrichten aus dem Bereich" en="Daily newsletter with the most important news in the field" no="Daglig nyhetsbrev med de viktigste nyhetene innen området" sv="Dagligt nyhetsbrev med de viktigaste nyheterna inom området" /> : <Translation da="Daglige nyhedsbreve med de vigtigste branchenyheder" de="Tägliche Newsletter mit den wichtigsten Updates" en="Daily newsletters with the most important industry news" no="Daglige nyhetsbrev med de viktigste bransjenyhetene" sv="Dagliga nyhetsbrev med de viktigaste branschnyheterna" />}
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerCard.components.tsx">
          {isMonitor ? <Translation da="Ekstra nyhedsbreve når de største nyheder breaker" de="Zusätzliche Newsletter, wenn die größten Nachrichten brechen" en="Extra newsletters when the biggest news breaks" no="Ekstra nyhetsbrev når de største nyhetene bryter" sv="Extra nyhetsbrev när de största nyheterna bryter" /> : <Translation da="Topnyhedsbreve når de største branchenyheder breaker" de="Eilmeldungen per E-Mail, damit Sie immer auf dem Laufenden sind" en="Top newsletters when the biggest industry news breaks" no="Toppnyhetsbrev når de største bransjenyhetene bryter" sv="Toppnyhetsbrev när de största branschnyheterna bryter" />}
        </ListItem>
        {apps?.appStoreUrl !== null && apps?.googlePlayUrl !== null ? <ListItem>
            <Translation da="Login til vores app - branchenyheder med på farten" de="Anmeldung zu unserer App - Branchennachrichten unterwegs" en="Login to our app - industry news on the go" no="Logg inn på appen vår - bransjenyheter på farten" sv="Logga in på vår app - branschnyheter på språng" />
          </ListItem> : null}
      </List>
      {billingPeriod === "Quarterly" && pricePerQuarter !== null ? <div>
          <p className="font-semibold">
            <Translation da="Kvartalsvis" de="Vierteljährlich" en="Quarterly" no="Kvartalsvis" sv="Kvartalsvis" />
          </p>
          <h3 className="mb-2 text-2xl font-bold">{`${pricePerQuarter}*`}</h3>
        </div> : null}
      <RegularLinkButton disabled={isPending(state)} href={url} position="pw-bbtn" variant={variant} data-sentry-element="RegularLinkButton" data-sentry-source-file="ConsumerCard.components.tsx">
        <Translation da="Køb" de="Kaufen" en="Buy" no="Kjøp" sv="Köp" data-sentry-element="Translation" data-sentry-source-file="ConsumerCard.components.tsx" />
      </RegularLinkButton>
    </Card>;
}