import { addTrial } from "@/app/(sites)/_containers/_actions/add-trial";
import { useUser } from "@/contexts/user/user.context";
import type { Fetchable } from "@/services/utilities/fetchable";
import { failed, succeeded } from "@/services/utilities/fetchable";

export function useAddTrial() {
  const {
    model: { loggedIn },
  } = useUser();

  async function addTrialToCurrentProfile(
    articleId: string | null,
  ): Promise<Fetchable> {
    if (!loggedIn) {
      return failed("NOT_LOGGED_IN");
    }

    const result = await addTrial({
      articleId,
    });

    return result.status !== "CREATED" ? failed(result.status) : succeeded();
  }

  return {
    addTrialToCurrentProfile,
  };
}
